<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
}

html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.el-main {
  padding: 0 !important;
}

@media screen and (max-width: 500px) {
  .el-message {
    min-width: 300px !important;
  }

  .el-message-box {
    width: 300px !important;
  }

  .el-dialog {
    width: 340px !important;
  }

  .el-dialog__body {
    padding: 8px 16px !important;
  }

  /*.el-select, .el-input {*/
  /*  width: 180px !important;*/
  /*}*/

  /*修复组件移动端适配问题*/
  /*分页器*/
  .el-pagination {
    width: 100% !important;
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .el-pagination__total {
    width: 40% !important;
    height: 50px !important;
    line-height: 50px !important;
  }

  .el-pagination__sizes {
    width: 40% !important;
    height: 50px !important;
    line-height: 50px !important;
  }

  .btn-prev,
  .btn-next {
    width: 40px !important;
    padding: 0;
  }

  /*.el-pager {*/
  /*  width: 200px !important;*/
  /*}*/

  .el-pagination__jump {
    width: 80px !important;
    height: 50px !important;
    line-height: 50px !important;
    margin: 0 0 0 calc(100% / 2 - 120px);
  }
}

</style>
