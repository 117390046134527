export default {
    name: '简体中文',
    message: {
        // BackEndHome
        backend_home: {
            console: '用户后台',
            //  Header
            welcome: '欢迎！',
            subtitle: '向着星辰与深渊',
            //  Menu
            main_site: '官网',
            user_profile: '账号信息',
            finance: '财务管理',
            top_up: '余额充值',
            top_up_record: '充值记录',
            system_config: '系统设置',
        },
        // UserProfilePage
        user_profile: {
            //  BaseInfo
            base_info: '基本信息',
            user_id: '账号ID',
            nick_name: '用户名',
            email: '邮箱',
            role: '权限',
            role_user: '注册用户',
            role_admin: '管理员',
            register_time: '注册时间',
            last_login_time: '上次登入',
            balance: '余额',
            id_verify: '实名认证',
            id_verify_has: '已认证',
            id_verify_go: '立即认证',
            //  Popularize
            popularize: '推广',
            score: '积分',
            invitation_code: '邀请码',
            invitation_text: '邀请他人注册，获得积分',
            copy_invitation_link: '点击复制邀请链接',
            invited_num: '邀请人数',
            invite_link: '邀请链接',
            //  Announcement
            system_announcement: '系统公告',
            //  Help
            help: '使用帮助',
            communication: '客户服务',
            join_qq: '点击加入我们的QQ群',
            document: '教程文档',
            goto_document_site: '前往文档站点',
        },
        // UserIdVerifyPage
        user_verify: {
            verify: '实名认证',
            verify_type: '证件类型',
            verify_name: '证件姓名',
            verify_number: '证件号码',
            verify_name_placeholder: '证件姓名',
            verify_number_placeholder: '证件号码',
            submit: '提交',
        },
        // TopUpPage
        top_up: {
            top_up: '余额充值',
            payment: '支付方式',
            amount: '充值金额',
            top_up_tips: '提示',
            top_up_tips_content: '本站微信支付和云闪付正在开发，如有需要，可联系人工客服代充，0服务费。联系方式见qq交流群',
            top_up_submit: '提交',
            check_top_up_result: '查询结果',
        },
        // TopUpRecordPage
        top_up_record: {
            top_up_record: '充值记录',
            trade_id: '流水号',
            out_trade_no: '订单号',
            alipay_trade_no: '支付宝订单号',
            trade_amount: '交易金额',
            total_amount: '订单金额',
            buyer_pay_amount: '实际支付',
            create_time: '流水创建时间',
            gmt_create: '支付宝订单创建时间',
            gmt_payment: '支付时间',
            buyer_logon_id: '付款账户',
            trade_status: '状态',
            scan: '扫码',
            rescan: '重新扫码',
            top_up_status_wait_to_pay: '待支付',
            top_up_status_ok: '已完成',
            top_up_status_failure: '交易失败',
        },
    }
}