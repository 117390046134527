export default {
    name: 'English',
    message: {
        // BackEndHome
        backend_home: {
            console: 'Console',
            //  Header
            welcome: 'Welcome!',
            subtitle: 'Ad astra abyssosque!',
            //  Menu
            main_site: 'Main Site',
            user_profile: 'Account',
            finance: 'Finance',
            top_up: 'TopUp',
            top_up_record: 'TopUp Record',
            system_config: 'System Config',
        },
        // UserProfilePage
        user_profile: {
            //  BaseInfo
            base_info: 'BaseInfo',
            user_id: 'UserID',
            nick_name: 'NickName',
            email: 'Email',
            role: 'Role',
            role_user: 'User',
            role_admin: 'Admin',
            register_time: 'Register',
            last_login_time: 'LastLogin',
            balance: 'Balance',
            id_verify: 'IdVerify',
            id_verify_has: 'Verified',
            id_verify_go: 'Verify Now',
            //  Popularize
            popularize: 'Popularize',
            score: 'Score',
            invitation_code: 'InvitationCode',
            invitation_text: 'Invite user earn score.',
            copy_invitation_link: 'Click to copy invitation link.',
            invited_num: 'Invited users',
            invite_link: 'Invite Link',
            //  Announcement
            system_announcement: 'Announcement',
            //  Help
            help: 'Help',
            communication: 'Contact',
            join_qq: 'Join our QQ group.',
            document: 'Documents',
            goto_document_site: 'Go Docs Site',
        },
        // UserIdVerifyPage
        user_verify: {
            verify: 'IDVerify',
            verify_type: 'Type',
            verify_name: 'Name',
            verify_number: 'Number',
            verify_name_placeholder: 'Name',
            verify_number_placeholder: 'Number',
            submit: 'Submit',
        },
        // TopUpPage
        top_up: {
            top_up: 'TopUp',
            payment: 'Payment',
            amount: 'Amount',
            top_up_tips: 'Tip',
            top_up_tips_content: 'We only support Alipay temporary, if necessary, please contact us with QQ.',
            top_up_submit: 'Submit',
            check_top_up_result: 'Check Result',
        },
        // TopUpRecordPage
        top_up_record: {
            top_up_record: 'TopUp Record',
            trade_id: 'Trade Id',
            out_trade_no: 'Out Trade No',
            alipay_trade_no: 'Alipay Trade No',
            trade_amount: 'Trade Amount',
            total_amount: 'Total Amount',
            buyer_pay_amount: 'Actual Pay',
            create_time: 'Create Time',
            gmt_create: 'Alipay Trade Create',
            gmt_payment: 'Pay Time',
            buyer_logon_id: 'Payer',
            trade_status: 'Status',
            scan: 'Scan',
            rescan: 'Rescan',
            top_up_status_wait_to_pay: 'Waiting',
            top_up_status_ok: 'OK',
            top_up_status_failure: 'Failure',
        },
    }
}