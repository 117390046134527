import { createApp } from 'vue';
import App from './App.vue';
//引入路由
import router from "@/router";
//Element-UI-Plus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//VueX
import store from "@/store";
//VueCookie
import VueCookies from 'vue-cookies';
//剪切板
import VueClipBoard from 'vue-clipboard2';
//二维码
import QRCode from 'qrcode';
//引入i18n
import i18n from '@/i18n';
//Echarts
// import echarts from 'echarts';



//创建app
const app = createApp(App);
//载入路由
app.use(router);
//载入elementPlus
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
//载入store
app.use(store);
//载入i18n
app.use(i18n);

//VueCookie
app.use(VueCookies);
//剪切板
app.use(VueClipBoard);
//二维码
app.use(QRCode);
//Echarts
// app.use(echarts)

//一库左 hasaki
app.mount('#app');

export default app;


