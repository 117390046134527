import { createI18n } from "vue-i18n";
import zh from '@/i18n/lang/zh';
import en from '@/i18n/lang/en';

const i18n = createI18n({
    locale : 'zh',
    messages: {
        'zh' : zh,
        'en' : en,
    }
});

export default i18n;