import {createRouter, createWebHashHistory} from 'vue-router';
// import BackendHome from "@/views/BackendHome";
// import UserProfilePage from "@/views/console/UserProfilePage";
// import UserRegister from "@/views/UserRegister";
// import UserChangePassword from "@/views/UserChangePassword";
// import UserIdVerifyPage from "@/views/console/UserIdVerifyPage";
// import TopUpPage from "@/views/console/TopUpPage";
// import TopUpRecordPage from "@/views/console/TopUpRecordPage";
// import SystemConfigPage from "@/views/manage/SystemConfigPage";
// import RouterManagePage from "@/views/manage/RouterManagePage";
// import RouterEditPage from "@/views/manage/RouterEditPage";
// import RouterWanManagePage from "@/views/manage/RouterWanManagePage";
// import RouterWanEditPage from "@/views/manage/RouterWanEditPage";
// import RouterLanManagePage from "@/views/manage/RouterLanManagePage";
// import RouterLanEditPage from "@/views/manage/RouterLanEditPage";
// import VpsProductPage from "@/views/console/VpsProductPage";
// import ProxmoxNodeManagePage from "@/views/manage/ProxmoxNodeManagePage";
// import ProxmoxNodeEditPage from "@/views/manage/ProxmoxNodeEditPage";
// import VpsTemplateManagePage from "@/views/manage/VpsTemplateManagePage";
// import VpsTemplateEditPage from "@/views/manage/VpsTemplateEditPage";
// import IsoFileManagePage from "@/views/manage/IsoFileManagePage";
// import IsoFileEditPage from "@/views/manage/IsoFileEditPage";
// import VpsSourcePoolManagePage from "@/views/manage/VpsSourcePoolManagePage";
// import VpsSourcePoolEditPage from "@/views/manage/VpsSourcePoolEditPage";
// import VpsProductGroupManagePage from "@/views/manage/VpsProductGroupManagePage";
// import VpsProductGroupEditPage from "@/views/manage/VpsProductGroupEditPage";
// import VpsProductManagePage from "@/views/manage/VpsProductManagePage";
// import VpsProductEditPage from "@/views/manage/VpsProductEditPage";
// import VpsDiscountRoleManagePage from "@/views/manage/VpsDiscountRoleManagePage";
// import VpsDiscountRoleEditPage from "@/views/manage/VpsDiscountRoleEditPage";
// import VpsTemplateAllocatePage from "@/views/manage/VpsTemplateAllocatePage";
// import VpsOrderPage from "@/views/console/VpsOrderPage";
// import CouponTemplateManagePage from "@/views/manage/CouponTemplateManagePage";
// import CouponTemplateEditPage from "@/views/manage/CouponTemplateEditPage";
// import CouponTemplateLimitEditPage from "@/views/manage/CouponTemplateLimitEditPage";
// import UserManagePage from "@/views/manage/UserManagePage";
// import UserEditPage from "@/views/manage/UserEditPage";
// import CouponDeliveryPage from "@/views/manage/CouponDeliveryPage";
// import UserCouponManagePage from "@/views/manage/UserCouponManagePage";
// import UserCouponEditPage from "@/views/manage/UserCouponEditPage";
// import UserCouponLimitEditPage from "@/views/manage/UserCouponLimitEditPage";
// import UserCouponPage from "@/views/console/UserCouponPage";
// import VpsListPage from "@/views/console/VpsListPage";
// import VpsDetailsPage from "@/views/console/VpsDetailsPage";
// import VpsNetMapPage from "@/views/console/VpsNetMapPage";
// import VpsNetMapEditPage from "@/views/console/VpsNetMapEditPage";
// import VpsChangeOsPasswordPage from "@/views/console/VpsChangeOsPasswordPage";
// import VpsSetCdromPage from "@/views/console/VpsSetCdromPage";
// import VpsRenewalPage from "@/views/console/VpsRenewalPage";
// import VpsReinstallPage from "@/views/console/VpsReinstallPage";
// import AnnouncementManagePage from "@/views/manage/AnnouncementManagePage";
// import AnnouncementEditPage from "@/views/manage/AnnouncementEditPage";
// import SystemLogManagePage from "@/views/manage/SystemLogManagePage";
// import SystemLogPage from "@/views/console/SystemLogPage";
// import VpsOrderManagePage from "@/views/manage/VpsOrderManagePage";
// import VpsOrderEditPage from "@/views/manage/VpsOrderEditPage";
// import VpsUpgradePage from "@/views/console/VpsUpgradePage";
// import ProxmoxUserManagePage from "@/views/manage/ProxmoxUserManagePage";
// import ProxmoxUserEditPage from "@/views/manage/ProxmoxUserEditPage";
// import IndexHome from "@/views/IndexHome";
// import SystemInfoViewer from "@/views/manage/SystemInfoViewer";
// import OverviewPage from "@/views/console/OverviewPage";
// import IndexPage from "@/views/index/IndexPage";
// import McsmProductGroupManagePage from "@/views/manage/McsmProductGroupManagePage";
// import McsmProductGroupEditPage from "@/views/manage/McsmProductGroupEditPage";
// import McsmProductManagePage from "@/views/manage/McsmProductManagePage";
// import McsmProductEditPage from "@/views/manage/McsmProductEditPage";
// import McsmDiscountRoleManagePage from "@/views/manage/McsmDiscountRoleManagePage";
// import McsmDiscountRoleEditPage from "@/views/manage/McsmDiscountRoleEditPage";
// import McsmWebNodeManagePage from "@/views/manage/McsmWebNodeManagePage";
// import McsmWebNodeEditPage from "@/views/manage/McsmWebNodeEditPage";
// import McsmDaemonNodeManagePage from "@/views/manage/McsmDaemonNodeManagePage";
// import McsmDaemonNodeEditPage from "@/views/manage/McsmDaemonNodeEditPage";
// import McsmInstanceTemplateManagePage from "@/views/manage/McsmInstanceTemplateManagePage";
// import McsmInstanceTemplateEditPage from "@/views/manage/McsmInstanceTemplateEditPage";
// import McsmDockerImageManagePage from "@/views/manage/McsmDockerImageManagePage";
// import McsmDockerImageEditPage from "@/views/manage/McsmDockerImageEditPage";
// import McsmSourcePoolManagePage from "@/views/manage/McsmSourcePoolManagePage";
// import McsmSourcePoolEditPage from "@/views/manage/McsmSourcePoolEditPage";
// import VpsBackupPage from "@/views/console/VpsBackupPage";
// import UserLogin from "@/views/UserLogin";
// import SystemTaskPage from "@/views/console/SystemTaskPage";

const routes = [
    {
        path: '/login',
        name: 'UserLogin',
        component: () => import('@/views/UserLogin')
    },
    {
        path: '/register',
        name: 'UserRegister',
        component: () => import('@/views/UserRegister')
    },
    {
        path: '/changePassword',
        name: 'UserChangePassword',
        component: () => import('@/views/UserChangePassword')
    },
    //首页
    {
        path: '/',
        name: 'IndexHome',
        component: () => import('@/views/IndexHome'),
        children: [
            {
                path: '',
                name: 'IndexPage',
                component: () => import('@/views/index/IndexPage')
            },
        ],
    },
    //后台
    {
        path: '/backend',
        name: 'BackendHome',
        component: () => import('@/views/BackendHome'),
        children: [
            //用户后台
            {
                path: '/',
                redirect: {name: 'UserProfilePage'}
            },
            {
                path: '/overview',
                name: 'OverviewPage',
                component: () => import('@/views/console/OverviewPage')
            },
            {
                path: '/profile',
                name: 'UserProfilePage',
                component: () => import('@/views/console/UserProfilePage')
            },
            {
                path: '/idVerify',
                name: 'UserIdVerifyPage',
                component: () => import('@/views/console/UserIdVerifyPage')
            },
            {
                path: '/topUp',
                name: 'TopUpPage',
                component: () => import('@/views/console/TopUpPage')
            },
            {
                path: '/topUpRecord',
                name: 'TopUpRecordPage',
                component: () => import('@/views/console/TopUpRecordPage')
            },
            {
                path: '/myCoupon',
                name: 'UserCouponPage',
                component: () => import('@/views/console/UserCouponPage')
            },
            {
                path: '/vpsPurchase',
                name: 'VpsProductPage',
                component: () => import('@/views/console/VpsProductPage')
            },
            {
                path: '/vpsOrder',
                name: 'VpsOrderPage',
                component: () => import('@/views/console/VpsOrderPage')
            },
            {
                path: '/vpsList',
                name: 'VpsListPage',
                component: () => import('@/views/console/VpsListPage')
            },
            {
                path: '/vps',
                name: 'VpsDetailsPage',
                component: () => import('@/views/console/VpsDetailsPage')
            },
            {
                path: '/vps/netmap',
                name: 'VpsNetMapPage',
                component: () => import('@/views/console/VpsNetMapPage')
            },
            {
                path: '/vps/netmap/info',
                name: 'VpsNetMapEditPage',
                component: () => import('@/views/console/VpsNetMapEditPage')
            },
            {
                path: '/vps/pwd',
                name: 'VpsChangeOsPasswordPage',
                component: () => import('@/views/console/VpsChangeOsPasswordPage')
            },
            {
                path: '/vps/cdrom',
                name: 'VpsSetCdromPage',
                component: () => import('@/views/console/VpsSetCdromPage')
            },
            {
                path: '/vps/renew',
                name: 'VpsRenewalPage',
                component: () => import('@/views/console/VpsRenewalPage')
            },
            {
                path: '/vps/reinstall',
                name: 'VpsReinstallPage',
                component: () => import('@/views/console/VpsReinstallPage')
            },
            {
                path: '/vps/upgrade',
                name: 'VpsUpgradePage',
                component: () => import('@/views/console/VpsUpgradePage')
            },
            {
                path: '/vps/backup',
                name: 'VpsBackupPage',
                component: () => import('@/views/console/VpsBackupPage')
            },
            {
                path: '/log',
                name: 'SystemLogPage',
                component: () => import('@/views/console/SystemLogPage')
            },
            {
                path: '/task',
                name: 'SystemTaskPage',
                component: () => import('@/views/console/SystemTaskPage')
            },
            //管理后台
            {
                path: '/systemConfig',
                name: 'SystemConfigPage',
                component: () => import('@/views/manage/SystemConfigPage')
            },
            {
                path: '/systemViewer',
                name: 'SystemInfoViewer',
                component: () => import('@/views/manage/SystemInfoViewer')
            },
            {
                path: '/announcementManage',
                name: 'AnnouncementManagePage',
                component: () => import('@/views/manage/AnnouncementManagePage')
            },
            {
                path: '/announcementEdit',
                name: 'AnnouncementEditPage',
                component: () => import('@/views/manage/AnnouncementEditPage')
            },
            {
                path: '/mcsmWebNodeManage',
                name: 'McsmWebNodeManagePage',
                component: () => import('@/views/manage/McsmWebNodeManagePage')
            },
            {
                path: '/mcsmWebNodeEdit',
                name: 'McsmWebNodeEditPage',
                component: () => import('@/views/manage/McsmWebNodeEditPage')
            },
            {
                path: '/mcsmDaemonNodeManage',
                name: 'McsmDaemonNodeManagePage',
                component: () => import('@/views/manage/McsmDaemonNodeManagePage')
            },
            {
                path: '/mcsmDaemonNodeEdit',
                name: 'McsmDaemonNodeEditPage',
                component: () => import('@/views/manage/McsmDaemonNodeEditPage')
            },
            {
                path: '/mcsmInstanceTemplateManage',
                name: 'McsmInstanceTemplateManagePage',
                component: () => import('@/views/manage/McsmInstanceTemplateManagePage')
            },
            {
                path: '/mcsmInstanceTemplateEdie',
                name: 'McsmInstanceTemplateEditPage',
                component: () => import('@/views/manage/McsmInstanceTemplateEditPage')
            },
            {
                path: '/mcsmDockerImageManage',
                name: 'McsmDockerImageManagePage',
                component: () => import('@/views/manage/McsmDockerImageManagePage')
            },
            {
                path: '/mcsmDockerImageEdit',
                name: 'McsmDockerImageEditPage',
                component: () => import('@/views/manage/McsmDockerImageEditPage')
            },
            {
                path: '/mcsmProductGroupManage',
                name: 'McsmProductGroupManagePage',
                component: () => import('@/views/manage/McsmProductGroupManagePage')
            },
            {
                path: '/mcsmProductGroupEdit',
                name: 'McsmProductGroupEditPage',
                component: () => import('@/views/manage/McsmProductGroupEditPage')
            },
            {
                path: '/mcsmProductManage',
                name: 'McsmProductManagePage',
                component: () => import('@/views/manage/McsmProductManagePage')
            },
            {
                path: '/mcsmProductEdit',
                name: 'McsmProductEditPage',
                component: () => import('@/views/manage/McsmProductEditPage')
            },
            {
                path: '/mcsmDiscountRoleManage',
                name: 'McsmDiscountRoleManagePage',
                component: () => import('@/views/manage/McsmDiscountRoleManagePage')
            },
            {
                path: '/mcsmDiscountRoleEdit',
                name: 'McsmDiscountRoleEditPage',
                component: () => import('@/views/manage/McsmDiscountRoleEditPage')
            },
            {
                path: '/mcsmSourcePoolManage',
                name: 'McsmSourcePoolManagePage',
                component: () => import('@/views/manage/McsmSourcePoolManagePage')
            },
            {
                path: '/mcsmSourcePoolEdit',
                name: 'McsmSourcePoolEditPage',
                component: () => import('@/views/manage/McsmSourcePoolEditPage')
            },
            {
                path: '/routerManage',
                name: 'RouterManagePage',
                component: () => import('@/views/manage/RouterManagePage')
            },
            {
                path: '/routerEdit',
                name: 'RouterEditPage',
                component: () => import('@/views/manage/RouterEditPage')
            },
            {
                path: '/routerWanManage',
                name: 'RouterWanManagePage',
                component: () => import('@/views/manage/RouterWanManagePage')
            },
            {
                path: '/routerWanEdit',
                name: 'RouterWanEditPage',
                component: () => import('@/views/manage/RouterWanEditPage')
            },
            {
                path: '/routerLanManage',
                name: 'RouterLanManagePage',
                component: () => import('@/views/manage/RouterLanManagePage')
            },
            {
                path: '/routerLanEdit',
                name: 'RouterLanEditPage',
                component: () => import('@/views/manage/RouterLanEditPage')
            },
            {
                path: '/proxmoxNodeManage',
                name: 'ProxmoxNodeManagePage',
                component: () => import('@/views/manage/ProxmoxNodeManagePage')
            },
            {
                path: '/proxmoxNodeEdit',
                name: 'ProxmoxNodeEditPage',
                component: () => import('@/views/manage/ProxmoxNodeEditPage')
            },
            {
                path: '/proxmoxUserManage',
                name: 'ProxmoxUserManagePage',
                component: () => import('@/views/manage/ProxmoxUserManagePage')
            },
            {
                path: '/ProxmoxUserEdit',
                name: 'ProxmoxUserEditPage',
                component: () => import('@/views/manage/ProxmoxUserEditPage')
            },
            {
                path: '/vpsTemplateManage',
                name: 'VpsTemplateManagePage',
                component: () => import('@/views/manage/VpsTemplateManagePage')
            },
            {
                path: '/vpsTemplateEdit',
                name: 'VpsTemplateEditPage',
                component: () => import('@/views/manage/VpsTemplateEditPage')
            },
            {
                path: '/isoFileManage',
                name: 'IsoFileManagePage',
                component: () => import('@/views/manage/IsoFileManagePage')
            },
            {
                path: '/isoFileEdit',
                name: 'IsoFileEditPage',
                component: () => import('@/views/manage/IsoFileEditPage')
            },
            {
                path: '/sourcePoolManage',
                name: 'VpsSourcePoolManagePage',
                component: () => import('@/views/manage/VpsSourcePoolManagePage')
            },
            {
                path: '/psSourcePoolEdit',
                name: 'VpsSourcePoolEditPage',
                component: () => import('@/views/manage/VpsSourcePoolEditPage')
            },
            {
                path: '/vpsProductGroupManage',
                name: 'VpsProductGroupManagePage',
                component: () => import('@/views/manage/VpsProductGroupManagePage')
            },
            {
                path: '/vpsProductGroupEdie',
                name: 'VpsProductGroupEditPage',
                component: () => import('@/views/manage/VpsProductGroupEditPage')
            },
            {
                path: '/vpsProductManage',
                name: 'VpsProductManagePage',
                component: () => import('@/views/manage/VpsProductManagePage')
            },
            {
                path: '/vpsProductEdit',
                name: 'VpsProductEditPage',
                component: () => import('@/views/manage/VpsProductEditPage')
            },
            {
                path: '/vpsDiscountRoleManage',
                name: 'VpsDiscountRoleManagePage',
                component: () => import('@/views/manage/VpsDiscountRoleManagePage')
            },
            {
                path: '/vpsDiscountRoleEdit',
                name: 'VpsDiscountRoleEditPage',
                component: () => import('@/views/manage/VpsDiscountRoleEditPage')
            },
            {
                path: '/vpsTemplateAllocate',
                name: 'VpsTemplateAllocatePage',
                component: () => import('@/views/manage/VpsTemplateAllocatePage')
            },
            {
                path: '/couponTemplateManage',
                name: 'CouponTemplateManagePage',
                component: () => import('@/views/manage/CouponTemplateManagePage')
            },
            {
                path: '/couponTemplateEdit',
                name: 'CouponTemplateEditPage',
                component: () => import('@/views/manage/CouponTemplateEditPage')
            },
            {
                path: '/couponTemplateLimitEdit',
                name: 'CouponTemplateLimitEditPage',
                component: () => import('@/views/manage/CouponTemplateLimitEditPage')
            },
            {
                path: '/userManage',
                name: 'UserManagePage',
                component: () => import('@/views/manage/UserManagePage')
            },
            {
                path: '/userEdit',
                name: 'UserEditPage',
                component: () => import('@/views/manage/UserEditPage')
            },
            {
                path: '/couponDelivery',
                name: 'CouponDeliveryPage',
                component: () => import('@/views/manage/CouponDeliveryPage')
            },
            {
                path: '/userCouponManage',
                name: 'UserCouponManagePage',
                component: () => import('@/views/manage/UserCouponManagePage')
            },
            {
                path: '/userCouponEdit',
                name: 'UserCouponEditPage',
                component: () => import('@/views/manage/UserCouponEditPage')
            },
            {
                path: '/userCouponLimitEdit',
                name: 'UserCouponLimitEditPage',
                component: () => import('@/views/manage/UserCouponLimitEditPage')
            },
            {
                path: '/order/vps',
                name: 'VpsOrderManagePage',
                component: () => import('@/views/manage/VpsOrderManagePage')
            },
            {
                path: '/order/vps/edit',
                name: 'VpsOrderEditPage',
                component: () => import('@/views/manage/VpsOrderEditPage')
            },
            {
                path: '/systemLog',
                name: 'SystemLogManagePage',
                component: () => import('@/views/manage/SystemLogManagePage')
            },
        ]
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
});

export default router;